import React, { useState, useEffect, useCallback } from "react"
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import queryString from 'query-string';
import { useQuery } from 'react-query';
import { repositoryConfigs } from '../../utils/prismic-previews';
import { Layout } from "../../components/global/layout";
import AdvisorRegisterPersonalDetails from "../../components/forms/advisor_confirm_personal_details";
import AdvisorRequestPayment from "../../components/forms/advisor_proposal_request_payment"
import AdvisorConfidentialityAgreement from "../../components/forms/confidentiality_agreement";
import AdvisorConflictOfInterest from "../../components/forms/conflict_of_interest";
import Review from "../../components/forms/review";
import Done from "../../components/forms/done";
import { getExpert } from "../../api/sage";
import {getExpertProfile} from "../../api/associates";

const CONFIDENTIALITY_ACCEPTED_COLUMN_NAME = 'Confidentiality accepted'
const CONFLICT_OF_INTEREST_ACCEPTED_COLUMN_NAME = 'Conflict of interest accepted'
const SUBMITTED_TO_OI_COLUMN_NAME = 'Review submitted by Expert'
const EXPERT_PROFILE_ID_COLUMN_NAME = 'Advisor Profile URL'

const AdvisorsReviewProposal = ({ pageContext }) => {
    const {data, isLoading, isError, isSuccess} = useQuery(
        'expert',
        () => {
            const token = queryString.parse(window.location.search).token;

            if (!token) {
                throw new Error('Token is required');
            }

            return getExpert(token)
        },
        {
            retries: 3,
            staleTime: 10 * (60 * 1000), // 10 mins
            cacheTime: 15 * (60 * 1000), // 15 mins
        }
    );

    const [ expertProfileId, setExpertProfileId ] = useState(null);
    const {
        data: expertProfileData, isLoading: expertProfileIsLoading,
        isError: expertProfileIsError, isSuccess: expertProfileIsSuccess
    } = useQuery({
        queryKey: ['expert-profile', expertProfileId],
        queryFn: () => getExpertProfile(expertProfileId),
        enabled: !!expertProfileId,
    });


    const [ step, setStep ] = useState(null);
    useEffect(() => {
        if (step) {
            return;
        }

        if (isSuccess) {
            const { expert } = data;

            if (expert[EXPERT_PROFILE_ID_COLUMN_NAME]) {
                // Extract id from the URL
                const url = expert[EXPERT_PROFILE_ID_COLUMN_NAME];
                const match = url.match(/\/expert\/(\d+)/i);

                if (match && match.length > 1) {
                    setExpertProfileId(match[1]);
                }
            }

            if (expert[CONFIDENTIALITY_ACCEPTED_COLUMN_NAME].toLowerCase() !== 'yes') {
                setStep(1);
                return;
            }

            if (expert[CONFLICT_OF_INTEREST_ACCEPTED_COLUMN_NAME].toLowerCase() !== 'yes') {
                setStep(3);
                return;
            }

            if (expert[SUBMITTED_TO_OI_COLUMN_NAME].toLowerCase() !== 'yes') {
                setStep(4);
                return;
            }

            setStep(5);
        }

        if (expertProfileIsSuccess) {
            if (expertProfileData['status'] === 3 && step === 5) {
                setStep(6);
            }
        }
    }, [isSuccess, expertProfileIsSuccess, data, expertProfileData, step]);
    const onFormSubmit = useCallback((profileId = null) => {
        if (profileId) {
            setExpertProfileId(profileId);
        }

        setStep(step + 1);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [step, ]);

    const [ token, setToken ] = useState(null);
    useEffect(() => {
        const token = queryString.parse(window.location.search).token;
        setToken(token);
    }, []);

    return (
        <Layout pageTitle={`Review proposal`}
                metaTitle={'Review proposal'}
        >
            {(isLoading || expertProfileIsLoading) && <p>Loading...</p>}
            {(isError || expertProfileIsError) && <p>Error: Invalid URL. Please get in touch with your point of contact.</p>}
            {(isSuccess && !expertProfileIsLoading && !expertProfileIsError) &&
                <>
                    {step === 1 && <AdvisorRegisterPersonalDetails formSubmitCb={onFormSubmit} expert={data.expert} proposal={data.proposal} token={token}  profile={expertProfileData} />}
                    {step === 2 && <AdvisorConfidentialityAgreement formSubmitCb={onFormSubmit} expert={data.expert} proposal={data.proposal} token={token} profile={expertProfileData} />}
                    {step === 3 && <AdvisorConflictOfInterest formSubmitCb={onFormSubmit} expert={data.expert} proposal={data.proposal} token={token} profile={expertProfileData} />}
                    {step === 4 && <Review formSubmitCb={onFormSubmit} expert={data.expert} proposal={data.proposal} token={token} profile={expertProfileData} />}
                    {step === 5 && <AdvisorRequestPayment formSubmitCb={onFormSubmit} expert={data.expert} proposal={data.proposal} token={token} profile={expertProfileData} />}
                    {step === 6 && <Done formSubmitCb={onFormSubmit} expert={data.expert} proposal={data.proposal} token={token} profile={expertProfileData} />}
                </>
            }
        </Layout>
    )
}

export default withPrismicPreview(AdvisorsReviewProposal, repositoryConfigs)

