import React, { useState, useRef } from "react"
import { useForm } from "react-hook-form";
import ProgressBar from "../progress-bar/review_proposal_progress_bar";
import { REVIEW_STEP_DONE } from "../progress-bar/constants";

const PM_COLUMN_NAME = 'PM Email';

export const Done = ({ proposal }) => {
    return (
        <>
            <div className="c-proposal-review">
                <div className="container">
                    <div className="row">
                        <div className="c-proposal-review__form">
                            <ProgressBar currentStep={REVIEW_STEP_DONE} />
                            <div className="f-section">
                                <h3>Thank you</h3>

                                <p>
                                    Thank you for your collaboration on this project. We will take a look, and be in touch if any clarification is required.
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    We process payments within 30 days of client acceptance of your review. Please get in touch with me (<a href={`mailto:${proposal[PM_COLUMN_NAME]}`} target='_blank'>{proposal[PM_COLUMN_NAME]}</a>) if you have any issues.
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    We look forward to working together again soon.
                                </p>

                                <p>&nbsp;</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Done;