import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  CONFIDENTIALITY_AGREEMENT_STEP,
  CONFLICT_OF_INTEREST_STEP, PAYMENT_DETAILS_STEP, PERSONAL_DETAILS_STEP,
  REVIEW_PROPOSAL_STEP,
  REVIEW_STEP_DONE
} from './constants';

const ProgressBar = ({ currentStep }) => {
  return (
      <div className="f-reg-steps">
        <ul>
          <li className={classNames("f-reg-step", {"f-reg-step--active": currentStep === PERSONAL_DETAILS_STEP})}>
            <span className={classNames("f-reg-step__bar")} />
            Personal details
          </li>
          <li className={classNames("f-reg-step", {"f-reg-step--active": currentStep === CONFIDENTIALITY_AGREEMENT_STEP})}>
            <span className={classNames("f-reg-step__bar")} />
            Confidentiality
          </li>
          <li className={classNames("f-reg-step", {"f-reg-step--active": currentStep === CONFLICT_OF_INTEREST_STEP})}>
            <span className={classNames("f-reg-step__bar")} />
            Conflicts
          </li>
          <li className={classNames("f-reg-step", {"f-reg-step--active": currentStep === REVIEW_PROPOSAL_STEP})}>
            <span className={classNames("f-reg-step__bar")} />
            Paper review
          </li>
          <li className={classNames("f-reg-step", {"f-reg-step--active": currentStep === PAYMENT_DETAILS_STEP})}>
            <span className={classNames("f-reg-step__bar")} />
            Payment
          </li>

          <li className={classNames("f-reg-step", {"f-reg-step--active": currentStep === REVIEW_STEP_DONE})}>
            <span className={classNames("f-reg-step__bar")} />
            Done
          </li>
        </ul>
      </div>
  )
}

ProgressBar.propTypes = {
  currentStep: PropTypes.string.isRequired,
};
ProgressBar.defaultProps = {};

export default ProgressBar;
