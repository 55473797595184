async function getExpertProfile(id) {
    const response = await fetch(`${ process.env.GATSBY_ASSOCIATES_API_URL }/expert/${id}/`, {
        method: 'GET',
        headers: {
            Authorization: `apikey ${process.env.GATSBY_ASSOCIATES_API_USER}:${process.env.GATSBY_ASSOCIATES_API_KEY}`,
        }
    });

    return await response.json();
}

async function saveExpertProfile(id = null, data = {}) {
    let response = null;
    if (!id) {
        // Create form data object
        const formData = new FormData();

        Object.keys(data).forEach(key => {
            if (data[key] instanceof FileList) {
                formData.append(key, data[key][0]);
                return;
            }
            formData.append(key, data[key]);
        });

        response = await fetch(`${process.env.GATSBY_ASSOCIATES_API_URL}/expert/register/personal_details/`, {
            method: 'POST',
            body: formData,
            headers: {
                Authorization: `apikey ${process.env.GATSBY_ASSOCIATES_API_USER}:${process.env.GATSBY_ASSOCIATES_API_KEY}`,
            }}
        );
    } else {
        response = await fetch(`${process.env.GATSBY_ASSOCIATES_API_URL}/expert/${id}/`, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                Authorization: `apikey ${process.env.GATSBY_ASSOCIATES_API_USER}:${process.env.GATSBY_ASSOCIATES_API_KEY}`,
                'Content-Type': 'application/json',
            }}
        );
    }

    if (response.status >= 200 && response.status < 300) {
        return response;
    }

    const error = new Error(response.statusText);
    error.response = await response.json();
    throw error;
}

async function savePaymentDetails(id = null, data = {}) {
    data['status'] = 3;

    const response = await fetch(`${process.env.GATSBY_ASSOCIATES_API_URL}/expert/${id}/`, {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
            Authorization: `apikey ${process.env.GATSBY_ASSOCIATES_API_USER}:${process.env.GATSBY_ASSOCIATES_API_KEY}`,
            'Content-Type': 'application/json',
        }}
    );

    if (response.status >= 200 && response.status < 300) {
        return response;
    }

    const error = new Error(response.statusText);
    error.response = await response.json();
    throw error;
}

export { getExpertProfile, saveExpertProfile, savePaymentDetails };