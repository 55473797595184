import React, { useState, useRef } from "react"
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import * as _ from "lodash";
import Select from "../fields/select";
import {COUNTRY_CHOICES, TITLE_CHOICES} from "../../constants";
import ProgressBar from "../progress-bar/review_proposal_progress_bar";
import {PERSONAL_DETAILS_STEP} from "../progress-bar/constants";
import AdvisorsTCs from "../modals/AdvisorsTCs";
import PrivacyPolicy from "../modals/PrivacyPolicy";
import {useMutation} from "react-query";
import {saveExpertProfile} from "../../api/associates";
import {updateExpertProfile} from "../../api/sage";

const SelectField = React.forwardRef((props,ref) => {
    return <Select  {...props} />
});


export const AdvisorRegisterPersonalDetails = ({ profile = {}, formSubmitCb, token }) => {
    const { register, handleSubmit, control, formState: { errors }, setError } = useForm();

    const [selectedFile, setSelectedFile] = useState('');

    const [showTCsModal, setShowTCsModal] = useState(false);
    const [showPrivacyModal, setShowPrivacyModal] = useState(false);

    const formRef = useRef(null);

    const updateExpertProfileMutation = useMutation({
        mutationKey: ['update-expert-profile', profile?.id],
        mutationFn: (profileId) => {
            return updateExpertProfile(token, profileId);
        }
    });

    const saveExpertMutation = useMutation({
        mutationKey: ['save-expert-profile', profile?.id],
        mutationFn: (data) => {
            return saveExpertProfile(profile?.id || null, data);
        },
        onSuccess: async (response) => {
            if (!profile?.id) {
                const data = await response.json();

                if (data.id) {
                    await updateExpertProfileMutation.mutateAsync(data.id);
                    formSubmitCb(data.id);

                    return;
                }
            }

            formSubmitCb();
        },
        onError: (error) => {
            if (error?.response['expert']) {
                let focus = true;
                for(let fieldName in error.response['expert']) {
                    setError(fieldName, {type: '', message: error.response['expert'][fieldName][0]},
                        {shouldFocus: focus});
                    focus = false;
                }
            } else {
                formRef.current.scrollIntoView();
            }
        }
    });

    const onSubmit = (data) => {
        return saveExpertMutation.mutate(data);
    }

    const onCvUploadChange = (event) => {
        let { value } = event.target;

        if (value) {
            const startIndex =
                value.indexOf('\\') >= 0
                    ? value.lastIndexOf('\\')
                    : value.lastIndexOf('/');

            const filename = value.substring(startIndex);
            if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
                value = filename.substring(1);
            }

            setSelectedFile(value);
        } else {
            setSelectedFile('');
        }
  }

    return (
        <>
            <div className="c-proposal-review" ref={formRef}>
                <div className="container">
                    <div className="row">
                        <div className="c-proposal-review__form">
                            <ProgressBar currentStep={PERSONAL_DETAILS_STEP} />
                            <div className="f-section">
                                <h3>Personal Details</h3>
                                <form className="c-form" onSubmit={handleSubmit(onSubmit)}>
                                    {saveExpertMutation.isError && (
                                      <div className="f-messages">
                                          <div className="f-messages__alert f-messages__alert--error">
                                              <p>{saveExpertMutation.error?.response['error_message']}</p>
                                          </div>
                                      </div>
                                    )}

                                    <>
                                        <div className="row">
                                            <div className="f-field">
                                                <div className={classNames("f-field__elem",{"f-field__elem--has-error": errors.first_name})}>
                                                    <label htmlFor="first_name">First Name</label>
                                                    <input
                                                        name="first_name" className="f-control" type="text"
                                                        placeholder="Please enter your first name(s)"
                                                        {...register("first_name", { required: "This field is required" })}
                                                        defaultValue={profile?.first_name}
                                                    />
                                                    {errors.first_name && <span className="f-error">{errors.first_name.message}</span>}
                                                </div>
                                            </div>

                                            <div className="f-field">
                                                <div className={classNames("f-field__elem",{"f-field__elem--has-error": errors.last_name})}>
                                                    <label htmlFor="last_name">Surname</label>
                                                    <input
                                                        name="last_name" className="f-control" type="text"
                                                        placeholder="Please enter your surname or family name"
                                                        {...register("last_name", { required: "This field is required" })}
                                                        defaultValue={profile?.last_name}
                                                    />
                                                    {errors.last_name && <span className="f-error">{errors.last_name.message}</span>}
                                                </div>
                                            </div>

                                            <div className="f-field">
                                                <div className={classNames("f-field__elem",{"f-field__elem--has-error": errors.email})}>
                                                    <label htmlFor="email">Email</label>
                                                    <input
                                                        name="email" className="f-control" type="text"
                                                        placeholder="Email Address"
                                                        {...register("email", { required: "This field is required", pattern: {value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: "Enter a valid email address."}})}
                                                        defaultValue={profile?.email}
                                                    />
                                                    {errors.email && <span className="f-error">{errors.email.message}</span>}
                                                </div>
                                            </div>

                                            <div className="f-field">
                                                <div className={classNames("f-field__elem",{"f-field__elem--has-error": errors.phone})}>
                                                    <label htmlFor="phone">Phone</label>
                                                    <input
                                                        name="phone" className="f-control" type="text"
                                                        placeholder="Please enter your phone number"
                                                        {...register("phone", { required: "This field is required" })}
                                                        defaultValue={profile?.phone}
                                                    />
                                                    {errors.phone && <span className="f-error">{errors.phone.message}</span>}
                                                </div>
                                            </div>

                                            <div className="f-field">
                                                <div className={classNames("f-field__elem",{"f-field__elem--has-error": errors.title})}>
                                                    <label htmlFor="title">Title</label>

                                                    <Controller
                                                        control={control}
                                                        name="title"
                                                        rules={{ required: 'This field is required' }}
                                                        render={({ field }) => <SelectField {...field} data={TITLE_CHOICES} />}
                                                        defaultValue={profile?.title}
                                                    />

                                                    {errors.title && <span className="f-error">{errors.title.message}</span>}
                                                </div>
                                            </div>

                                            <div className="f-field">
                                                <div className={classNames("f-field__elem",{"f-field__elem--has-error": errors.nationality})}>
                                                    <label htmlFor="nationality">Nationality</label>

                                                    <Controller
                                                        control={control}
                                                        name="nationality"
                                                        rules={{ required: 'This field is required' }}
                                                        render={({ field }) => <SelectField {...field} data={COUNTRY_CHOICES} />}
                                                        defaultValue={profile?.nationality}
                                                    />

                                                    {errors.nationality && <span className="f-error">{errors.nationality.message}</span>}
                                                </div>
                                            </div>

                                            {!profile?.id &&
                                                <>
                                                    <div className="f-field f-field--full">
                                                        <p>
                                                            Please provide a link to your LinkedIn profile page and
                                                            upload a resume/CV. Alternatively you may do this later if your CV is
                                                            not readily available.
                                                        </p>
                                                    </div>

                                                    <div className="f-field f-field--full">
                                                        <div className={classNames("f-field__elem",{"f-field__elem--has-error": errors.linkedin})}>
                                                            <label htmlFor="linkedin">Linkedin</label>
                                                            <input
                                                                name="linkedin" className="f-control" type="text"
                                                                placeholder="Please enter url to your Linkedin profile"
                                                                {...register("linkedin")}
                                                            />
                                                            {errors.linkedin && <span className="f-error">{errors.linkedin.message}</span>}
                                                        </div>
                                                    </div>

                                                    <div className="f-field f-field--full">
                                                        <div className={classNames("f-field__elem",{"f-field__elem--has-error": errors.cv_upload})}>
                                                            <label htmlFor={"cv_upload"}>Attach your CV</label>

                                                            <div>
                                                                <input
                                                                  name="cv_upload"
                                                                  id="cv_upload"
                                                                  type="file"
                                                                  className="inputfile inputfile-1"
                                                                  {...register("cv_upload", {onChange: onCvUploadChange})}
                                                                />
                                                                <label htmlFor="cv_upload">
                                                                  {_.isEmpty(selectedFile) ? (
                                                                    <span>Select a file to upload</span>
                                                                  ) : (
                                                                    <span>{selectedFile}</span>
                                                                  )}

                                                                  <strong>BROWSE</strong>
                                                                </label>
                                                            </div>
                                                            {errors.cv_upload
                                                                ? (<span className="f-error">{errors.cv_upload.message}</span>)
                                                                : (<span className="f-info">Please make sure your file is in one of these formats: PDF, JPG, PNG</span>)
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className="f-field f-field--full">
                                                        <div className={classNames("f-field__elem checkbox",{"f-field__elem--has-error": errors.agree_terms}, "tcs")}>
                                                            <input
                                                                className="f-control"
                                                                type="checkbox"
                                                                {...register("agree_terms", {required: "This field is required"})}
                                                            />
                                                            <label>
                                                                I agree to{' '}
                                                                <a
                                                                    href="#showAdvisorsTCModal"
                                                                    role="button"
                                                                    onClick={e => {
                                                                        e.preventDefault();
                                                                        setShowTCsModal(true);
                                                                    }}
                                                                    className="policy"
                                                                >
                                                                    Advisors Terms & Conditions
                                                                </a>{' '}
                                                                and{' '}
                                                                <a
                                                                    href="#showPrivacyPolicyModal"
                                                                    role="button"
                                                                    onClick={e => {
                                                                        e.preventDefault();
                                                                        setShowPrivacyModal(true);
                                                                    }}
                                                                    className="policy"
                                                                >
                                                                    Privacy Policy
                                                                </a>
                                                            </label>
                                                            {errors.agree_terms && <span className="f-error">{errors.agree_terms.message}</span>}
                                                        </div>
                                                    </div>
                                                </>
                                            }

                                            <div className="f-field f-field--full">
                                                <button
                                                    type="submit"
                                                    className="c-btn c-btn--large c-btn--primary"
                                                >
                                                    SUBMIT
                                                </button>
                                                {(saveExpertMutation.isLoading || updateExpertProfileMutation.isLoading) && <p>Loading...</p>}
                                            </div>
                                        </div>
                                    </>

                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <AdvisorsTCs showModal={showTCsModal} onHide={() => setShowTCsModal(false)} />
            <PrivacyPolicy showModal={showPrivacyModal} onHide={() => setShowPrivacyModal(false)} />
        </>
    )
}

export default AdvisorRegisterPersonalDetails;